import { Environment } from "../websocket/websocket.constant";

export const SKIN_FETCH_BACKUP = "skin/SKIN_FETCH_BACKUP";
export const SKIN_FETCH_BACKUP_SUCCESS = "skin/SKIN_FETCH_BACKUP_SUCCESS";
export const SKIN_FETCH_BACKUP_FAIL = "skin/SKIN_FETCH_BACKUP_FAIL";
export const SKIN_SET = "skin/SKIN_SET";

export interface FormMandatory {
  email?: boolean;
  name?: boolean;
  surname?: boolean;
  referralCode?: boolean;
  promoCode?: boolean;
  countryIsoCode?: boolean;
  state?: boolean;
  city?: boolean;
  zip?: boolean;
  address?: boolean;
  phoneNumber?: boolean;
  birthDate?: boolean;
  governmentId?: boolean;
}
export interface Palette {
  primary?: {
    main: string;
  };
  secondary?: {
    main: string;
  };
  error?: {
    main: string;
  };
  warning?: {
    main: string;
  };
  text?: {
    primary: string;
    secondary: string;
    disabled: string;
  };
  background?: {
    default: string;
    alternative?: string;
    form?: string;
  };
  gradient?: {
    primary: string;
    secondary?: string;
  };
}

interface SubskinOptions {
  skin: string;
  languageDefault: string;
  phoneDefaultCode: string;
}
export interface Alerts {
  city?: string;
  address?: string;
  state?: string;
  zip?: string;
  governmentId?: string;
}
export interface Regional {
  code: string;
  name: string;
}

export interface Downloads {
  Android?: string;
  Windows?: string;
  IOS?: string;
  Mac?: string;
}

interface Ommit {
  usernameHelperText?: boolean;
  usernameAlert?: boolean;
  email?: boolean;
  emailHelperText?: boolean;
  name?: boolean;
  nameAlert?: boolean;
  surname?: boolean;
  referralCodeLabel?: boolean;
  mandatoryLabel?: boolean;
  passwordHelperText?: boolean;
  passwordRepeatHelperText?: boolean;
  countryCodes?: boolean;
  birthDate?: boolean;
  promoCode?: boolean;
}

export interface Skin {
  skin: string;
  environment?: Environment;
  title?: string;
  favicon?: string;
  languages: string[];
  usernamePlaceholder?: string;
  usernameBlacklist?: string[];
  usernameBlacklistAlert?: string;
  emailPlaceholder?: string;
  namePlaceholder?: string;
  surnamePlaceholder?: string;
  editableReferralCode?: boolean;
  referralCodePlaceholder?: string;
  promoCodePlaceholder?: string;
  cancelUrl?: string;
  mandatoryFields?: FormMandatory;
  passwordPlaceholder?: string;
  passwordRepeatPlaceholder?: string;
  strongPass?: boolean;
  countryCodesDefault?: string;
  stateLabel?: string;
  stateDefault?: string;
  strictStates?: boolean;
  stateForbidden?: string[];
  stateHelperText?: string;
  cityLabel?: string;
  cityPlaceholder?: string;
  addressLabel?: string;
  addressPlaceholder?: string;
  zipLabel?: string;
  zipPlaceholder?: string;
  zipStrict?: boolean;
  phoneCustomLabel?: string;
  phoneCustomAlert?: string;
  phoneDefaultCode?: string;
  phonePlaceholder?: string;
  phoneMasks?: { [key: string]: number[] };
  governmentIdLabel?: string;
  governmentIdPlaceholder?: string;
  governmentIdStrictNumber?: boolean;
  governmentIdLimitTop?: number;
  governmentIdLimitLow?: number;
  governmentIdHelperText?: string;
  governmentIdCustomHelperTextColor?: string;
  governmentIdDropdown?: string[];
  palette: Palette;
  alerts?: Alerts;
  callbackUrl?: string;
  callbackText?: string;
  downloads?: Downloads;
  instantPlayUrl?: string;
  iosManualUrl?: string;
  dismissableManual?: boolean;
  successDescription?: string;
  redirectionDialogTitle?: string;
  redirectionDialogAccept?: boolean;
  redirectionDialogDecline?: boolean;
  ommitedFields?: Ommit;
  termsOfService?: string;
  termsOfServiceCheckbox?: true;
  termsOfServiceLinkPart1?: string;
  termsOfServiceLinkPart2?: string;
  termsOfServiceURL?: string;
  over18Checkbox?: boolean;
  subskinOptions?: SubskinOptions[];
  rootAgent?: string;
  highlightSubmit?: boolean;
  trackingIdGA?: string;
  singlePage?: boolean;
}
