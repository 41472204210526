import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  emailVerify,
  formUniqueEmailSet,
  phoneVerify,
  requestPhoneVerificationSuccess,
} from "../../core/form/form.action";
import { webSocketSubject } from "../../core/form/form.reducer";
import { navigationPageSet } from "../../core/navigation/navigation.action";
import { useStoreState } from "../../core/store/store.hook";
import { validitySet } from "../../core/validity/validity.action";
import { WSProtocol } from "../../core/websocket/websocket.constant";
import { useOnMount } from "../../global/hooks/on-mount.hook";

export const Hack = () => {
  const dispatch = useDispatch();

  const phoneMustBeVerified = useStoreState(
    (state) => state.form.formState.phoneMustBeVerified
  );
  const emailMustBeVerified = useStoreState(
    (state) => state.form.formState.emailMustBeVerified
  );
  const [created, setCreated] = useState(false);
  const [readyToUse, setReadyToUse] = useState(false);
  const singlePage = useStoreState((state) => state.skin.skin.singlePage);

  useOnMount(() => {
    function simpleSubscribe$(func: (data: any) => void) {
      return webSocketSubject?.subscribe(
        (msg) => {
          func(msg);
        },
        (err) => console.log(err), // Called if at any point WebSocket API signals some kind of error.
        () => console.log("connection to server closed") // Called when connection is closed (for whatever reason).)
      );
    }
    simpleSubscribe$((data) => {
      const message = data?.ServerMsg?.[0];

      if (!message) {
        if (data?.Settings?.PhoneMustBeVerified?.toLowerCase() === "true") {
          dispatch(phoneVerify(true));
        }
        if (data?.Settings?.PhoneMustBeVerified?.toLowerCase() === "false") {
          dispatch(phoneVerify(false));
        }
        if (data?.Settings?.EmailMustBeVerified?.toLowerCase() === "true") {
          dispatch(emailVerify(true));
        }
        if (data?.Settings?.EmailMustBeVerified?.toLowerCase() === "false") {
          dispatch(emailVerify(false));
        }
        return;
      }

      const messageType = message.MsgType;

      switch (messageType) {
        case WSProtocol.AccountUsernameUniqueResponse:
          if (!message.Value) {
            dispatch(validitySet({ usernameTaken: true }));
            break;
          }
          dispatch(validitySet({ usernameTaken: false }));
          break;
        case WSProtocol.AccountEmailUniqueResponse:
          if (!message.Value) {
            dispatch(formUniqueEmailSet(false));
            break;
          }
          dispatch(formUniqueEmailSet(true));
          break;
        case WSProtocol.AccountPhoneUniqueResponse:
          if (!message.Value) {
            dispatch(validitySet({ phoneTaken: true }));
            break;
          }
          dispatch(validitySet({ phoneTaken: false }));
          break;
        case WSProtocol.AccountValidateReferralCodeResponse:
          if (!message.Value) {
            dispatch(validitySet({ referralCode: false }));
            break;
          }
          dispatch(validitySet({ referralCode: true }));
          break;
        case WSProtocol.CheckPromoCodeResponse:
          if (message.Value !== 1) {
            dispatch(validitySet({ promoCode: false }));
            break;
          }
          dispatch(validitySet({ promoCode: true }));
          break;
        case WSProtocol.AccountCreateResponse:
          if (message.Value) {
            setCreated(true);
          }
          break;
        case WSProtocol.AppReadyToUse:
          setReadyToUse(true);
          break;
        case WSProtocol.RequestPhoneCodeResponse:
          if (message.Value === 1) {
            dispatch(requestPhoneVerificationSuccess());
            break;
          }
          dispatch(validitySet({ dialog: message.Text }));
          break;
        case WSProtocol.SubmitPhoneCodeResponse:
          if (message.Value === 1) {
            if (!singlePage) {
              dispatch(navigationPageSet("step2"));
            }
            dispatch(validitySet({ phoneVerified: true }));
            break;
          }
          dispatch(validitySet({ dialog: message.Text }));
          break;
        case WSProtocol.Error:
          dispatch(validitySet({ dialog: message.Text }));
          break;
        default:
          break;
      }
    });
  });
  useEffect(() => {
    if (created) {
      dispatch(navigationPageSet("success"));
      return;
    }
    if (phoneMustBeVerified && !singlePage) {
      dispatch(navigationPageSet("step1"));
      return;
    }
  }, [created, dispatch, phoneMustBeVerified, singlePage]);
  useEffect(() => {
    if (readyToUse) {
      if (phoneMustBeVerified) {
        return;
      }
      if (emailMustBeVerified) {
        dispatch(navigationPageSet("success"));
      }
    }
  });
  return <></>;
};
