import { useOnMount } from "../../global/hooks/on-mount.hook";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  backupSkinFetch,
  backupSkinFetchFail,
  skinSet,
} from "../../core/skin/skin.action";
import {
  fetchTranslationsBackup,
  fetchTranslationsBackupFail,
  languageSet,
  translationsSet,
} from "../../core/translations/translations.action";
import { useStoreState } from "../../core/store/store.hook";
import { remoteConfig } from "../../global/firebase/firebase";
import { LoadingPage } from "../loading/loading.component";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";
import { myUrlParams } from "../../global/url-extractor/url-extractor";
import { DEBUG, Environment } from "../../environment";
import { format, sub } from "date-fns";
import {
  formSet,
  subjectClear,
  websocketConnect,
} from "../../core/form/form.action";
import { webSocketSubject } from "../../core/form/form.reducer";
import { Skin } from "../../core/skin/skin.constants";
import ReactGA from "react-ga";
import { Hack } from "../hack/hack";

const TRANSLATIONS = `translations`;

i18n.use(initReactI18next).init({
  resources: {},
  lng: "en",
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  appendNamespaceToMissingKey: true,
});

export const SkinProvider = (props: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const { translations, loadedTranslations, language } = useStoreState(
    (state) => state.translations
  );
  const [loadingSkin, setLoadingSkin] = useState(true);
  const [loadingTranslations, setLoadingTranslations] = useState(true);
  const loadedSkin = useStoreState((state) => state.skin.loaded);
  const skin = useStoreState((state) => state.skin.skin);
  const formData = useStoreState((state) => state.form.formState.form);
  const [socketOppened, setSocketOppened] = useState(false);
  const [gaInitialized, setGaInitialized] = useState(false);

  useEffect(() => {
    if (skin.trackingIdGA && loadedSkin && !gaInitialized) {
      ReactGA.initialize(skin.trackingIdGA);
      setGaInitialized(true);
    }
  }, [gaInitialized, loadedSkin, skin]);

  useEffect(() => {
    if (gaInitialized) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [gaInitialized]);

  const agentCode = myUrlParams().find((param) => param.key === "referral")
    ?.value;

  const rootAgent = skin.rootAgent ?? skin.skin;

  useOnMount(() => {
    dispatch(subjectClear());
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        remoteConfig.ensureInitialized();
        const skinsFirebase = remoteConfig.getValue("skins").asString();
        const trans = remoteConfig.getValue(TRANSLATIONS).asString();
        if (!skin) {
          setLoadingSkin(false);
        }
        if (skin) {
          const urlNoParams =
            window.location.href.indexOf("?") > -1
              ? window.location.href.substr(
                  0,
                  window.location.href.indexOf("?")
                )
              : window.location.href;
          dispatch(
            skinSet(
              JSON.parse(skinsFirebase).find(
                (skinFirebase: Skin) =>
                  urlNoParams.toLowerCase().replace("www.", "") ===
                  skinFirebase.skin.toLowerCase()
              ) ||
                JSON.parse(skinsFirebase).find(
                  (skinFirebase: Skin) => skinFirebase.skin === "debug"
                )
            )
          );
          setTimeout(() => {
            setLoadingSkin(false);
          }, 5000);
        }
        if (trans) {
          dispatch(translationsSet(JSON.parse(trans)));
          setTimeout(() => {
            setLoadingTranslations(false);
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  useEffect(() => {
    if (!loadedSkin && !loadingSkin) {
      DEBUG ? dispatch(backupSkinFetchFail()) : dispatch(backupSkinFetch());
    }
    if (!loadedTranslations && !loadingTranslations) {
      DEBUG
        ? dispatch(fetchTranslationsBackupFail())
        : dispatch(fetchTranslationsBackup());
    }
  }, [
    dispatch,
    loadedSkin,
    loadedTranslations,
    loadingSkin,
    loadingTranslations,
  ]);

  useEffect(() => {
    if (!loadedTranslations) {
      return;
    }
    for (const translation of translations) {
      i18n.addResource(
        translation.language,
        translation.namespace,
        translation.key,
        translation.value
      );
    }
  }, [loadedTranslations, translations]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if (loadedSkin && loadedTranslations) {
      !socketOppened &&
        dispatch(websocketConnect(skin.environment ?? Environment));
      setSocketOppened(true);
      dispatch(languageSet(skin.languages[0] === "en" ? "rs" : "en"));
      dispatch(languageSet(skin.languages[0]));
      dispatch(
        formSet({
          ...formData,
          skin: rootAgent,
          countryIsoCode:
            formData.countryIsoCode || skin.countryCodesDefault || undefined,
          referralCode:
            (formData.referralCode === rootAgent || !formData.referralCode
              ? agentCode
              : formData.referralCode) || "",
          birthDate: skin.ommitedFields?.birthDate
            ? undefined
            : formData.birthDate ||
              format(sub(new Date(), { years: 18 }), "yyyy-LL-dd"),
          password: "",
          repeatPassword: "",
          code: "",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agentCode,
    dispatch,
    loadedSkin,
    loadedTranslations,
    skin.countryCodesDefault,
    skin.languages,
    skin.ommitedFields,
  ]);

  if (!(loadedSkin && loadedTranslations && webSocketSubject)) {
    return <LoadingPage />;
  }

  return (
    <div>
      <Hack />
      <I18nextProvider i18n={i18n}>{props.children}</I18nextProvider>
    </div>
  );
};
