import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";
import * as actions from "./navigation.action";
import { NAVIGATION_PAGE_SET } from "./navigation.constants";

type FormState = Readonly<{
  page: string;
}>;

type FormAction = ActionType<typeof actions>;

const initialState: FormState = {
  page: "form",
};

export const navigationReducer = combineReducers<FormState, FormAction>({
  page: (state = initialState.page, action) => {
    switch (action.type) {
      case NAVIGATION_PAGE_SET:
        return action.payload;
      default:
        return state;
    }
  },
});
