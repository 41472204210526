import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useStoreState } from "../../core/store/store.hook";
import { validitySet } from "../../core/validity/validity.action";
import CloseIcon from "@material-ui/icons/Close";
import { NARROW } from "../../App";

export const FormDialog = () => {
  const { t } = useTranslation();
  const validity = useStoreState((state) => state.validity.validity);

  const dispatch = useDispatch();

  return (
    <div>
      <Dialog
        open={validity.dialog === "loading"}
        style={{ backgroundColor: "00000000" }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogContent
          style={{
            padding: 0,
            height: NARROW ? 75 : 100,
            overflow: "hidden",
            backgroundColor: "00000000",
          }}
        >
          <img
            //stavi ovo da zavisi od konfiga
            src={"/registration/loading.png"}
            style={{
              height: NARROW ? 75 : undefined,
              backgroundColor: "00000000",
            }}
          ></img>
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!validity.dialog && validity.dialog !== "loading"}
        onClose={() => {
          dispatch(validitySet({ dialog: null }));
        }}
      >
        <DialogTitle
          disableTypography
          style={{
            padding: 0,
          }}
        >
          <IconButton
            style={{ float: "right" }}
            onClick={() => {
              dispatch(validitySet({ dialog: null }));
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            width: 300,
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Typography
            variant={"h6"}
            color={"textPrimary"}
            style={{
              color: "black",
              maxWidth: 300,
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 30,
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            {validity.dialog &&
              validity.dialog !== "loading" &&
              t(validity.dialog).replace("translation:", "")}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};
