import { Button, Divider, Grid, Typography, Box } from "@material-ui/core";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { COUNTRY_ISO_CODES } from "../../global/fallback/iso-codes";
import { isStrictEmail } from "../../global/string-checks/strict-email";
import {
  isStrictPassword,
  isStrongPassword,
} from "../../global/string-checks/strict-password";
import { isStrictString } from "../../global/string-checks/strict-string";
import { FormElement } from "./form-element.component";
import "react-phone-input-2/lib/style.css";
import { format } from "date-fns";
import {
  isStrictNumber,
  isStrictPhoneNumber,
} from "../../global/string-checks/strict-number";
import { useTranslation } from "react-i18next";
import { Translations } from "../translations/translations.component";
import { visitUrl } from "../../global/http/external-linker";
import { useStoreState } from "../../core/store/store.hook";
import { myUrlParams } from "../../global/url-extractor/url-extractor";
import { useDispatch } from "react-redux";
import {
  formSet,
  lockAgentCode,
  websocketSet,
} from "../../core/form/form.action";
import {
  isStrictUsername,
  isUsernameBlacklisted,
} from "../../global/string-checks/strict-username";
import { useOnMount } from "../../global/hooks/on-mount.hook";
import { WSProtocol } from "../../core/websocket/websocket.constant";
import { AutocompleteLocal } from "../autocomplete/autocomplete.component";
import { TermsOfService } from "../terms-of-service/terms-of-service.container";
import csc from "country-state-city";
import { FormSubmit } from "./form-submit.component";
import { validitySet } from "../../core/validity/validity.action";
import { FormDialog } from "./form-dialog.component";
import { Over18 } from "../over18/over18.container";
import { browserName, browserVersion } from "react-device-detect";
import { v4 } from "uuid";
import { ElementDate } from "./element-date.component";
import { replaceAll } from "../../global/string-checks/utils";
import { PhoneWithVerificationField } from "../multistep-form/phone-with-verification-field.container";

export const Form = (): JSX.Element => {
  const { t } = useTranslation();
  const agentCode = myUrlParams().find((param) => param.key === "referral")
    ?.value;
  const { addToast } = useToasts();
  const skin = useStoreState((state) => state.skin.skin);
  const rootAgent = skin.rootAgent ?? skin.skin;
  const dispatch = useDispatch();
  const [noEmail, setNoEmail] = useState<boolean>(true);
  const validity = useStoreState((state) => state.validity.validity);
  const formData = useStoreState((state) => state.form.formState.form);
  const emailTaken = !useStoreState(
    (state) => state.form.formState.uniqueEmail
  );
  const lockedAgentCode = useStoreState(
    (state) => state.form.formState.lockAgentCode
  );
  const phoneVerificationRequested = useStoreState(
    (state) => state.form.formState.phoneVerificationRequested
  );

  useOnMount(() => {
    dispatch(
      websocketSet({
        type: WSProtocol.Version,
        Str1: "Registration", //name
        Str2: skin.rootAgent || skin.skin, //skin / referral
        Str3: 1, //version
        Str4: `${browserName} ${browserVersion}`, //OS
        Str5: v4(), //guid
        Value2: 2, //platform
      })
    );
    if (agentCode) {
      dispatch(lockAgentCode());
      dispatch(formSet({ ...formData, referralCode: agentCode }));
    }
    if (formData.referralCode) {
      dispatch(
        websocketSet({
          type: WSProtocol.AccountValidateReferralCode,
          Str1: formData.referralCode,
        })
      );
    }
    if (formData.promoCode) {
      dispatch(
        websocketSet({
          type: WSProtocol.CheckPromoCode,
          Str1: formData.promoCode,
          Value: 1,
        })
      );
    }
    // setInterval(() => {
    //   dispatch(websocketSet({ type: WSProtocol.Ping, Value2: 1 }));
    // }, 5000);
  });

  return (
    <div
      style={{
        backgroundImage: "url(/registration/background.jpg)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      <Grid container>
        <Grid item xl={5} lg={4} md={3} sm={2} xs={1}></Grid>
        <Grid
          item
          xl={2}
          lg={4}
          md={6}
          sm={8}
          xs={10}
          style={{
            marginTop: 30,
            marginBottom: 20,
          }}
        >
          <Box
            style={{
              display: "flex",
              alignSelf: "center",
              paddingTop: 10,
              justifyContent: "center",
            }}
          >
            <img
              src={"/registration/logo.png"}
              alt="logo"
              style={{
                height: 65,
                objectFit: "contain",
                marginBottom: 15,
              }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor:
                skin.palette.background?.form || "rgba(1, 38, 63, 0.9)",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 10,
              }}
            >
              <Box
                style={{
                  height: 30,
                  flex: 1,
                  marginBottom: 10,
                  marginLeft: 15,
                }}
              ></Box>
              <Typography
                variant="h5"
                color={"textPrimary"}
                gutterBottom
                style={{
                  flex: 5,
                  flexWrap: "wrap",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {t("title")}
              </Typography>
              <Translations
                containerStyle={{
                  marginBottom: 7,
                  marginTop: 3,
                  marginRight: 15,
                  flex: 1,
                  backgroundColor: "#00000033",
                }}
                variant={"outlined"}
              />
            </Box>

            <FormElement
              label={t(`username`)}
              mandatory={true}
              defaultValue={formData.username}
              placeholder={skin.usernamePlaceholder}
              onChange={(username) => {
                if (username.length > 4 && username.length < 21) {
                  dispatch(
                    websocketSet({
                      type: WSProtocol.AccountVerifyUsernameUnique,
                      Str1: username,
                    })
                  );
                }
                if (username.length < 5) {
                  dispatch(validitySet({ usernameTaken: false }));
                }
                dispatch(
                  formSet({
                    ...formData,
                    username,
                    email:
                      skin.ommitedFields?.email ||
                      (noEmail && !skin.mandatoryFields?.email)
                        ? `${username}@example.com`
                        : formData.email,
                  })
                );
                if (
                  skin.ommitedFields?.email ||
                  (noEmail && skin.mandatoryFields?.email)
                ) {
                  dispatch(
                    websocketSet({
                      Type: WSProtocol.AccountVerifyEmailUnique,
                      Str1: `${username}@example.com`,
                    })
                  );
                }
                //proveri da li je ovo uzrok bug-a
                if (username.length > 20) {
                  dispatch(validitySet({ usernameTaken: false }));
                }
              }}
              validateText={(str) =>
                str.length > 4 &&
                !validity.usernameTaken &&
                str.length < 13 &&
                (!skin.usernameBlacklist ||
                  !isUsernameBlacklisted(str, skin.usernameBlacklist))
              }
              strictText={(str) => isStrictUsername(str)}
              helperText={
                skin.ommitedFields?.usernameHelperText
                  ? undefined
                  : t(
                      validity.usernameTaken
                        ? "usernameTaken"
                        : formData.username.length < 5 &&
                          formData.username !== ""
                        ? "usernameShortHelperText"
                        : formData.username.length > 12
                        ? "usernameLongHelperText"
                        : skin.usernameBlacklist &&
                          skin.usernameBlacklistAlert &&
                          isUsernameBlacklisted(
                            formData.username,
                            skin.usernameBlacklist
                          )
                        ? skin.usernameBlacklistAlert
                        : "usernameHelperText"
                    )
              }
              strictAlert={
                !skin.ommitedFields?.usernameAlert
                  ? () =>
                      addToast(t("strictUsername"), {
                        appearance: "warning",
                        autoDismiss: true,
                      })
                  : undefined
              }
            />

            {!skin.ommitedFields?.email && (
              <FormElement
                label={t("email")}
                mandatory={skin.mandatoryFields?.email}
                defaultValue={
                  formData.email.search("@example.com") === -1
                    ? formData.email
                    : ""
                }
                placeholder={skin.emailPlaceholder && t(skin.emailPlaceholder)}
                onChange={(email) => {
                  if (email.length > 4 && isStrictEmail(email)) {
                    dispatch(
                      websocketSet({
                        Type: WSProtocol.AccountVerifyEmailUnique,
                        Str1: email,
                      })
                    );
                  }
                  dispatch(
                    formSet({
                      ...formData,
                      email: email || `${formData.username}@example.com`,
                    })
                  );
                  if (email) {
                    if (noEmail) {
                      setNoEmail(false);
                    }
                  } else {
                    setNoEmail(true);
                  }
                }}
                validateText={(str) =>
                  !emailTaken && isStrictEmail(str) && str.length < 50
                }
                strictText={(str) => !str.includes("\n") && !str.includes(" ")}
                filterText={(str) => replaceAll(str, " ", "")}
                helperText={
                  skin.ommitedFields?.emailHelperText
                    ? undefined
                    : t(
                        emailTaken
                          ? "emailTaken"
                          : formData.email.length > 50
                          ? "emailLongHelperText"
                          : "emailHelperText"
                      )
                }
              />
            )}
            {
              <FormElement
                label={t("password")}
                mandatory={true}
                placeholder={skin.passwordPlaceholder}
                onChange={(password) =>
                  dispatch(formSet({ ...formData, password }))
                }
                validateText={(str) =>
                  (skin.strongPass
                    ? isStrongPassword(str)
                    : isStrictPassword(str)) && str.length < 30
                }
                type={"password"}
                helperText={
                  skin.ommitedFields?.passwordHelperText
                    ? undefined
                    : t(
                        formData.password.length > 29
                          ? "passwordLongHelperText"
                          : formData.password === formData.username
                          ? "alertPasswordSameAsUsername"
                          : skin.strongPass
                          ? "strongPasswordHelperText"
                          : "passwordHelperText"
                      )
                }
              />
            }
            {
              <FormElement
                label={t("passwordRepeat")}
                mandatory={true}
                placeholder={skin.passwordRepeatPlaceholder}
                onChange={(repeatPassword) =>
                  dispatch(formSet({ ...formData, repeatPassword }))
                }
                strictText={(str) => str.length < 30}
                validateText={(str) => str === formData.password}
                type={"password"}
                helperText={
                  skin.ommitedFields?.passwordRepeatHelperText
                    ? undefined
                    : t("passwordRepeatHelperText")
                }
              />
            }
            {!skin.ommitedFields?.name && (
              <FormElement
                label={t("name")}
                mandatory={skin.mandatoryFields?.name}
                defaultValue={formData.name}
                placeholder={skin.namePlaceholder ?? ""}
                onChange={(name) => dispatch(formSet({ ...formData, name }))}
                strictText={(str) => isStrictString(str)}
                validateText={(str) => str.length < 20}
                strictAlert={
                  skin.ommitedFields?.nameAlert
                    ? () =>
                        addToast(t("strictName"), {
                          appearance: "warning",
                          autoDismiss: true,
                        })
                    : undefined
                }
                helperText={formData.name ? t("nameHelperText") : undefined}
              />
            )}
            {!skin.ommitedFields?.surname && (
              <FormElement
                label={t("surname")}
                mandatory={skin.mandatoryFields?.surname}
                defaultValue={formData.surname}
                placeholder={skin.surnamePlaceholder ?? ""}
                onChange={(surname) =>
                  dispatch(formSet({ ...formData, surname }))
                }
                strictText={(str) => isStrictString(str) && str.length < 20}
              />
            )}
            {skin.phoneDefaultCode &&
              (skin.singlePage ? (
                <PhoneWithVerificationField
                  label={t("phoneWithVerification")}
                  onChange={(phoneNumber) => {
                    dispatch(
                      websocketSet({
                        type: WSProtocol.AccountVerifyPhoneUnique,
                        Str1: phoneNumber,
                      })
                    );
                    dispatch(formSet({ ...formData, phoneNumber }));
                  }}
                  onClick={() => {
                    if (validity.phoneTaken) {
                      addToast(<p>{`${t("alertTakenPhone")}\n`}</p>, {
                        appearance: "warning",
                        autoDismiss: true,
                      });
                      return;
                    }
                    dispatch(
                      websocketSet({
                        type: WSProtocol.RequestPhoneCode,
                        Str1: formData.username,
                        Str2: formData.email,
                        Str3: formData.phoneNumber,
                      })
                    );
                  }}
                />
              ) : (
                <FormElement
                  phoneDefaultCode={
                    skin.subskinOptions?.find(
                      (option) => option.skin === agentCode
                    )?.phoneDefaultCode || skin.phoneDefaultCode
                  }
                  placeholder={skin.phonePlaceholder}
                  label={t("phone")}
                  mandatory={skin.mandatoryFields?.phoneNumber}
                  onChange={(phoneNumber) => {
                    dispatch(
                      websocketSet({
                        type: WSProtocol.AccountVerifyPhoneUnique,
                        Str1: phoneNumber,
                      })
                    );
                    dispatch(formSet({ ...formData, phoneNumber }));
                  }}
                  validateText={(phone) =>
                    !skin.phoneMasks ||
                    !!Object.keys(skin.phoneMasks).find(
                      (mask) =>
                        phone?.substring(1, mask.length + 1) === mask &&
                        !!skin.phoneMasks?.[mask].find(
                          (length) => length === (phone?.length ?? 0) - 1
                        )
                    )
                  }
                  helperText={t("alertPhoneMaskCountry")}
                  palette={skin.palette}
                />
              ))}
            {phoneVerificationRequested && (
              <FormElement
                label={t("phoneVerificationCodeField")}
                mandatory={true}
                onChange={(code) => dispatch(formSet({ ...formData, code }))}
                defaultValue={formData.code}
              />
            )}
            {skin.phoneCustomLabel && (
              <FormElement
                label={t(skin.phoneCustomLabel)}
                mandatory={skin.mandatoryFields?.phoneNumber}
                defaultValue={formData.phoneNumber}
                placeholder={skin.phonePlaceholder ?? ""}
                onChange={(phoneNumber) => {
                  dispatch(
                    websocketSet({
                      type: WSProtocol.AccountVerifyPhoneUnique,
                      Str1: phoneNumber,
                    })
                  );
                  dispatch(formSet({ ...formData, phoneNumber }));
                }}
                strictText={(str: string) => isStrictPhoneNumber(str)}
              />
            )}
            {!skin.ommitedFields?.birthDate && (
              <ElementDate
                label={`${t("birthDate")}${
                  skin.mandatoryFields?.birthDate ? "*" : ""
                }`}
                onChange={(birthDate) => {
                  dispatch(
                    formSet({
                      ...formData,
                      birthDate: format(birthDate, "yyyy-LL-dd"),
                    })
                  );
                }}
              />
            )}

            {!skin.ommitedFields?.countryCodes && (
              <AutocompleteLocal
                label={`${t("countryCodes")}${
                  skin.mandatoryFields?.countryIsoCode ? "*" : ""
                }`}
                list={COUNTRY_ISO_CODES}
                onSelect={(selection) =>
                  dispatch(
                    formSet({
                      ...formData,
                      countryIsoCode: selection?.code,
                    })
                  )
                }
                style={{
                  marginTop: 3,
                  marginBottom: 3,
                  marginLeft: 15,
                  marginRight: 15,
                  flex: 1,
                }}
                paperStyle={{
                  color: skin.palette.text?.primary,
                  backgroundColor: skin.palette.background?.alternative,
                }}
                defaultValue={formData.countryIsoCode}
              />
            )}

            {skin.stateLabel &&
              (skin.ommitedFields?.countryCodes || formData.countryIsoCode) &&
              (skin.strictStates ? (
                <AutocompleteLocal
                  label={`${t(skin.stateLabel)}${
                    skin.mandatoryFields?.state ? "*" : ""
                  }`}
                  list={
                    skin.ommitedFields?.countryCodes
                      ? csc
                          .getAllStates()
                          .map((state) => ({
                            code: state.isoCode,
                            value: state.name,
                          }))
                          .filter(
                            (state) =>
                              !skin.stateForbidden?.find(
                                (forbidden) => forbidden === state.value
                              )
                          )
                      : csc
                          //formData.countryIsoCode will allways be true if this condition is resolved
                          .getStatesOfCountry(formData.countryIsoCode!)
                          .map((state) => ({
                            code: state.isoCode,
                            value: state.name,
                          }))
                          .filter(
                            (state) =>
                              !skin.stateForbidden?.find(
                                (forbidden) => forbidden === state.value
                              )
                          )
                  }
                  onSelect={(selection) =>
                    dispatch(formSet({ ...formData, state: selection?.value }))
                  }
                  style={{
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 15,
                    marginRight: 15,
                    flex: 1,
                  }}
                  paperStyle={{
                    color: skin.palette.text?.primary,
                    backgroundColor: skin.palette.background?.alternative,
                  }}
                />
              ) : (
                <FormElement
                  label={t(skin.stateLabel)}
                  mandatory={skin.mandatoryFields?.state}
                  onChange={(code) =>
                    dispatch(formSet({ ...formData, state: code }))
                  }
                  palette={skin.palette}
                  defaultValue={formData.state || skin.stateDefault}
                  validateText={(str) =>
                    !skin.stateForbidden?.find(
                      (state) => state.toLowerCase() === str.toLowerCase()
                    )
                  }
                  helperText={
                    skin.stateHelperText ? t(skin.stateHelperText) : undefined
                  }
                />
              ))}
            {skin.cityLabel && (
              <FormElement
                label={t(skin.cityLabel)}
                mandatory={skin.mandatoryFields?.city}
                onChange={(city) => dispatch(formSet({ ...formData, city }))}
                placeholder={skin.cityPlaceholder}
                defaultValue={formData.city}
              />
            )}
            {skin.zipLabel && (
              <FormElement
                label={t(skin.zipLabel)}
                mandatory={skin.mandatoryFields?.zip}
                onChange={(zip) => dispatch(formSet({ ...formData, zip }))}
                placeholder={skin.zipPlaceholder}
                strictText={
                  skin.zipStrict ? (str) => isStrictNumber(str) : undefined
                }
                defaultValue={formData.zip}
              />
            )}
            {skin.addressLabel && (
              <FormElement
                label={t(skin.addressLabel)}
                mandatory={skin.mandatoryFields?.address}
                onChange={(address) =>
                  dispatch(formSet({ ...formData, address }))
                }
                placeholder={skin.addressPlaceholder}
                defaultValue={formData.address}
              />
            )}
            {skin.governmentIdLabel &&
              (skin.governmentIdDropdown ? (
                <AutocompleteLocal
                  label={`${t(skin.governmentIdLabel)}${
                    skin.mandatoryFields?.governmentId ? "*" : ""
                  }`}
                  list={skin.governmentIdDropdown.map((element) => ({
                    code: element,
                    value: element,
                  }))}
                  onSelect={(selection) =>
                    dispatch(
                      formSet({
                        ...formData,
                        governmentId: selection?.code,
                      })
                    )
                  }
                  style={{
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 15,
                    marginRight: 15,
                    flex: 1,
                  }}
                  paperStyle={{
                    color: skin.palette.text?.primary,
                    backgroundColor: skin.palette.background?.alternative,
                  }}
                  defaultValue={formData.governmentId}
                />
              ) : (
                <FormElement
                  label={t(skin.governmentIdLabel)}
                  mandatory={skin.mandatoryFields?.governmentId}
                  defaultValue={formData.governmentId}
                  onChange={(governmentId) =>
                    dispatch(formSet({ ...formData, governmentId }))
                  }
                  placeholder={skin.governmentIdPlaceholder}
                  strictText={
                    skin.governmentIdStrictNumber
                      ? (str) => isStrictNumber(str)
                      : undefined
                  }
                  validateText={(str) =>
                    (skin.governmentIdLimitTop
                      ? !(str.length > skin.governmentIdLimitTop)
                      : true) &&
                    (skin.governmentIdLimitLow
                      ? !(str.length < skin.governmentIdLimitLow)
                      : true)
                  }
                  helperText={
                    skin.governmentIdHelperText
                      ? t(skin.governmentIdHelperText)
                      : undefined
                  }
                  customHelperColor={skin.governmentIdCustomHelperTextColor}
                />
              ))}

            {!skin.ommitedFields?.referralCodeLabel && (
              <FormElement
                label={t("referralCodeLabel")}
                mandatory={skin.mandatoryFields?.referralCode}
                placeholder={skin.referralCodePlaceholder}
                onChange={(code) => {
                  if (code.length) {
                    dispatch(
                      websocketSet({
                        type: WSProtocol.AccountValidateReferralCode,
                        Str1: code,
                      })
                    );
                  }
                  dispatch(
                    formSet({
                      ...formData,
                      referralCode: code || rootAgent,
                    })
                  );
                }}
                defaultValue={agentCode || formData.referralCode}
                disabled={
                  skin.editableReferralCode === false ||
                  (!!agentCode && !skin.editableReferralCode) ||
                  lockedAgentCode
                }
                helperText={t("referralCodeHelperText")}
                validateText={(str) => validity.referralCode || str === ""}
              />
            )}
            {!skin.ommitedFields?.promoCode && (
              <FormElement
                label={t("promoCodeLabel")}
                mandatory={skin.mandatoryFields?.promoCode}
                placeholder={skin.promoCodePlaceholder}
                onChange={(code) => {
                  if (code.length) {
                    dispatch(
                      websocketSet({
                        type: WSProtocol.CheckPromoCode,
                        Str1: code,
                        Value: 1,
                      })
                    );
                  }
                  dispatch(
                    formSet({
                      ...formData,
                      promoCode: code,
                    })
                  );
                }}
                defaultValue={formData.promoCode}
                helperText={t("promoCodeHelperText")}
                validateText={(str) => validity.promoCode || str === ""}
              />
            )}
            {(skin.termsOfService ||
              skin.termsOfServiceURL ||
              skin.termsOfServiceCheckbox) && <TermsOfService />}
            {skin.over18Checkbox ? <Over18 /> : null}

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Typography
                gutterBottom
                color={"textPrimary"}
                style={{ fontFamily: "arial" }}
              >
                {t("mandatoryLabel")}
              </Typography>
            </Box>

            <Divider />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 4,
              }}
            >
              {skin.cancelUrl ? (
                <Button
                  style={{
                    marginLeft: 5,
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                  variant={"outlined"}
                  onClick={() => {
                    skin.cancelUrl && visitUrl(skin.cancelUrl);
                  }}
                >
                  {t("cancel")}
                </Button>
              ) : null}
              <FormSubmit />
            </Box>
          </Box>
        </Grid>
        <Grid item xl={5} lg={4} md={3} sm={2} xs={1}></Grid>
      </Grid>
      <FormDialog />
      {/* <Hack /> */}
    </div>
  );
};
