import {
  Box,
  createStyles,
  LinearProgress,
  Theme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { NARROW } from "../../App";
import { DEBUG } from "../../environment";

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 3,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[700],
    },
    bar: {
      borderRadius: 3,
      backgroundImage: "linear-gradient(to right, #FFFFFF44 , #FFFFFFEE)",
    },
  })
)(LinearProgress);

export const LoadingPage = (): JSX.Element => {
  return (
    <Box
      style={{
        backgroundColor: "#03011c",
        backgroundImage: `${
          DEBUG ? "/background.jpg" : `url(/registration/background.jpg)`
        }`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "00000000",
        }}
      >
        <img
          src={"/registration/loading.png"}
          //stavi da je velicina ovde nesto sto se passuje u firebase-u
          style={{
            height: NARROW ? 70 : 100,
            backgroundColor: "00000000",
          }}
        />
        <BorderLinearProgress />
      </Box>
    </Box>
  );
};
