export const VALIDITY_SET = "form/VALIDITY_SET";

export interface Validity {
  phoneTaken: boolean;
  usernameTaken: boolean;
  referralCode: boolean;
  promoCode: boolean;
  dialog: string | null;
  phoneVerified: boolean;
}
