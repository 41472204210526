import {
  createStyles,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  withStyles,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Palette, Regional } from "../../core/skin/skin.constants";

export const StyledTextField = withStyles(() =>
  createStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#FFFFFF55",
        },
      },
      "& .MuiInputLabel-root": {
        color: "#AAAAAAAA",
        "&.Mui-focused": {
          color: "white",
        },
      },
      "& .MuiIconButton-root": {
        color: "white",
      },
    },
  })
)(TextField);

export const FormElement = ({
  label,
  placeholder,
  onChange,
  defaultValue,
  disabled,
  strictText,
  validateText,
  type,
  selectList,
  phoneDefaultCode,
  mandatory,
  helperText,
  palette,
  strictAlert,
  customHelperColor,
  filterText,
}: {
  label: string;
  placeholder?: string;
  onChange: (value: string) => void;
  defaultValue?: string;
  disabled?: boolean;
  strictText?: (text: string) => boolean;
  validateText?: (text: string) => boolean;
  type?: string;
  selectList?: Regional[];
  phoneDefaultCode?: string;
  mandatory?: boolean;
  helperText?: string;
  palette?: Palette;
  strictAlert?: () => void;
  customHelperColor?: string;
  filterText?: (text: string) => string;
}): JSX.Element => {
  const [value, setValue] = useState<string>(defaultValue || "");
  const [selected, setSelected] = useState<boolean>(false);
  const [hovering, setHovering] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 15,
        marginRight: 15,
      }}
      onFocus={() => selected || setSelected(true)}
      onBlur={() => selected && setSelected(false)}
      onMouseEnter={() => hovering || setHovering(true)}
      onMouseLeave={() => hovering && setHovering(false)}
    >
      <div style={{ flex: 5 }}>
        {phoneDefaultCode ? (
          <PhoneInput
            containerStyle={{
              width: "100%",
              height: 56,
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
            buttonStyle={{
              width: 40,
              height: 56,
              backgroundColor: "rgba(0, 0, 0, 0)",
              borderColor: "rgba(0, 0, 0, 0)",
            }}
            inputStyle={{
              width: "100%",
              height: 56,
              backgroundColor: "rgba(0, 0, 0, 0)",
              borderColor: selected
                ? palette?.primary?.main
                : hovering
                ? palette?.text?.primary
                : "#FFFFFF55",
              color: palette?.text?.primary,
            }}
            country={phoneDefaultCode}
            onChange={(event) => {
              onChange(`+${event}`);
            }}
            enableSearch={true}
            placeholder={placeholder}
            defaultErrorMessage={helperText}
          />
        ) : (
          <StyledTextField
            FormHelperTextProps={
              !value && customHelperColor
                ? {
                    style: {
                      color: customHelperColor,
                    },
                  }
                : undefined
            }
            label={`${label}${mandatory ? "*" : ""}`}
            placeholder={placeholder}
            variant="outlined"
            multiline={!type && !selectList}
            fullWidth
            rowsMax={4}
            onChange={(event) => {
              let text = event.target.value;
              if (filterText) {
                text = filterText(text);
              }
              if (strictText && !strictText?.(text)) {
                strictAlert?.();
                return;
              }
              setValue(text);
              onChange(text);
            }}
            disabled={disabled}
            error={value !== "" && validateText?.(value) === false}
            helperText={(validateText?.(value) === false && helperText) || ""}
            value={value}
            type={showPassword ? undefined : type}
            select={!!selectList}
            InputProps={{
              endAdornment:
                type === "password" ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                      color={"secondary"}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
            }}
          >
            {selectList?.map((option) => (
              <MenuItem
                key={option.code}
                value={option.code}
                style={{ backgroundColor: palette?.text?.secondary }}
              >
                {`${option.code} - ${option.name}`}
              </MenuItem>
            ))}
          </StyledTextField>
        )}
      </div>
    </div>
  );
};
