export const FORM_SET = "form/FORM_SET";
export const ACCOUNT_CREATE = "form/ACCOUNT_CREATE";
export const FORM_UNIQUE_EMAIL_SET = "form/FORM_UNIQUE_EMAIL_SET";
export const FORM_CLEAR = "form/FORM_CLEAR";
export const SUBJECT_CLEAR = "form/SUBJECT_CLEAR";
export const WEBSOCKET_CONNECT = "form/WEBSOCKET_CONNECT";
export const WEBSOCKET_SET = "form/WEBSOCKET_SET";
export const ACCOUNT_CONNECT = "form/ACCOUNT_CONNECT";
export const PHONE_VERIFY = "form/PHONE_VERIFY";
export const EMAIL_VERIFY = "form/EMAIL_VERIFY";
export const REQUEST_PHONE_VERIFICATION = "form/REQUEST_PHONE_VERIFICATION";
export const REQUEST_PHONE_VERIFICATION_SUCCESS =
  "form/REQUEST_PHONE_VERIFICATION_SUCCESS";
export const REQUEST_PHONE_VERIFICATION_FAIL =
  "form/REQUEST_PHONE_VERIFICATION_FAIL";
export const SUBMIT_PHONE_VERIFICATION = "form/SUBMIT_PHONE_VERIFICATION";
export const SUBMIT_PHONE_VERIFICATION_SUCCESS =
  "form/SUBMIT_PHONE_VERIFICATION_SUCCESS";
export const SUBMIT_PHONE_VERIFICATION_FAIL =
  "form/SUBMIT_PHONE_VERIFICATION_FAIL";
export const LOCK_AGENT_CODE = "form/LOCK_AGENT_CODE";

export interface FormData {
  skin: string;
  username: string;
  email: string;
  password: string;
  repeatPassword: string;
  name?: string;
  surname?: string;
  referralCode?: string;
  promoCode?: string;
  countryIsoCode?: string;
  state?: string;
  city?: string;
  zip?: string;
  address?: string;
  phoneNumber?: string;
  code?: string; //sms verification code
  birthDate?: string;
  governmentId?: string;
  acceptedTos?: boolean;
  is18?: boolean;
}
