import { Grid, Box, Typography, Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { formSet, websocketSet } from "../../core/form/form.action";
import { useStoreState } from "../../core/store/store.hook";
import { WSProtocol } from "../../core/websocket/websocket.constant";
import { useOnMount } from "../../global/hooks/on-mount.hook";
import { FormElement } from "../form/form-element.component";

export const PhoneVerification = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formData = useStoreState((state) => state.form.formState.form);

  useOnMount(() =>
    dispatch(
      websocketSet({
        type: WSProtocol.RequestPhoneCode,
        Str1: formData.phoneNumber,
      })
    )
  );

  return (
    <div
      style={{
        backgroundImage: "url(/registration/background.jpg)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      <Grid container>
        <Grid item xl={5} lg={4} md={3} sm={2} xs={1}></Grid>
        <Grid
          item
          xl={2}
          lg={4}
          md={6}
          sm={8}
          xs={10}
          style={{
            marginTop: 30,
            marginBottom: 20,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              marginBottom: 10,
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                src={"/registration/logo.png"}
                alt="logo"
                style={{
                  height: 30,
                  margin: 10,
                }}
              />
              <Typography
                variant="h5"
                color={"textPrimary"}
                gutterBottom
                style={{
                  flex: 5,
                  flexWrap: "wrap",
                  textAlign: "center",
                  paddingTop: 10,
                }}
              >
                {t("phoneVerificationTitle")}
              </Typography>
            </Box>
            <FormElement
              label={t("phoneVerificationCode")}
              onChange={(phoneVerificationCode) =>
                dispatch(
                  formSet({
                    ...formData,
                    code: phoneVerificationCode,
                  })
                )
              }
              helperText={t("phoneVerificationHelperText")}
            />

            <Box style={{ marginBottom: 10 }}></Box>
            <Button
              onClick={() =>
                dispatch(
                  websocketSet({
                    type: WSProtocol.SubmitPhoneCode,
                    Str1: formData.code,
                  })
                )
              }
            >
              {t("submit")}
            </Button>
          </Box>
        </Grid>
        <Grid item xl={5} lg={4} md={3} sm={2} xs={1}></Grid>
      </Grid>
    </div>
  );
};
